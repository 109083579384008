import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'primeicons/primeicons.css';
import Glow from './screens/glow';
import Scowl from './screens/scowl';
import Solstice from './screens/solstice';
import Sparkle from './screens/sparkle';
import Home from './screens/home';
import Goblin from './screens/goblin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/glow" element={<Glow />} />
        <Route path="/solstice" element={<Solstice />} />
        <Route path="/scowl" element={<Scowl />} />
        <Route path="/sparkle" element={<Sparkle />} />
        <Route path="/goblin" element={<Goblin />} />
      </Routes>
    </Router>
  )
}

export default App;