import React, { useState } from 'react';
import '../App.css';
import styled from 'styled-components';
import { Analytics } from '@vercel/analytics/react';
import dayjs from 'dayjs';
import 'primeicons/primeicons.css';

const AppContainer = styled.div`
  background-color: white;
  & .icon-section {
    padding: 0px 0 34px;
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 15px;
    }
    > div > div:nth-child(1) {
      font-size: 3rem;
    }
    > div > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: 100;
      font-size: 1.1rem;
    }
  }
`;

const HeroSection = styled.section`
  & {
    display: flex;
  }

  & > #info-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
  }

  & > #info-container > .top * {
    color: white;
  }

  & > #info-container > .top p {
    font-weight: 100;
  }

  & > #info-container > .top {
    margin: 15px 45px;
  }

  @media (min-width: 800px) {
    & > #info-container {
      padding-top: 15px;
    }
  }

  & > div:nth-child(1) {
    height: auto;
    flex: 4;
  }

  & > div:nth-child(1) > img {
    width: 100%;
    object-fit: cover;
    object-position: 70%;
  }

  & > div:nth-child(2) {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:nth-child(2) > h1 {
    font-size: 4.8rem;
    font-weight: 900;
    color: black;
    margin-bottom: 10px;
  }

  & > div:nth-child(2) > p {
    color: black;
    display: flex;
  }

  & > div:nth-child(2) > p > span:nth-child(1),
  & > div:nth-child(2) > p > span:nth-child(3), {
    font-weight: 900;
    font-size: 2.5rem;
  }

  & > div:nth-child(2) > p > span:nth-child(2), {
    margin: 0px;
    font-size:1rem;
  }

  & div#instructions.hidden {
    color: transparent;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions {
    padding: 5px 15px;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }

  & div#instructions.hidden ::marker {
    color: transparent;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.visible ::marker {
    color: #8f8f8f;
    font-weight: bold;
    transition: color .5s;
  }

  & div#instructions.hidden a {
    color: transparent;
    text-decoration: none;
    transition: color .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-color: transparent;
    border-radius: 34px;
  }

  & div#instructions.hidden strike {
    color: transparent;
    transition: color .5s;
  }

  & div#instructions.visible strike {
    color: red;
    transition: color .5s;
  }

  & div#instructions.visible {
    color: white;
    transition: color .5s;
    font-weight: normal;
  }

  & div#instructions.visible a {
    color: #57c6ff;
    text-decoration: none;
    transition: color .5s, opacity .5s, border-color .5s;
    font-weight: bold;
    padding: 5px 15px;
    border: 1px solid;
    border-radius: 34px;
    opacity: 1;
    border-color: #57c6ff;
  }

  & div#instructions.visible a:hover {
    opacity: .7;
  }

  & div#qr-venmo {
    text-align: center;
  }

  & div#continue-to-venmo {
    text-align: right;
    padding: 10px 0px;
  }

  & div#instructions.visible div#qr-venmo {
    opacity: 1;
    transition: opacity .5s;
  }

  & div#instructions.hidden div#qr-venmo {
    opacity: 0;
    transition: opacity .5s;
  }

  @media (max-width: 800px) {
    & {
      height: 1171px;
      flex-direction: column
    }
  }

  @media (max-width: 1148px) {
    & > div:nth-child(2) > h1 {
      font-size: 3rem;
      font-weight: 900;
      color: black;
      margin-bottom: 10px;
    }

    & > div:nth-child(2) > p > span:nth-child(1),
    & > div:nth-child(2) > p > span:nth-child(3) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    div#qr-venmo {
      display: none;
    }
  }

  @media (min-width: 801px) {
    div#continue-to-venmo {
      display: none;
    }
  }
`;

const TicketButton = styled.button`
  & {
    padding: 10px 36px;
    font-weight: 900;
    font-size: 1.25rem;
    border-radius: 38px;
    background: #be00ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 1s;
    color: black !important;
  }

  &:hover {
    opacity: .7;
    transition: opacity 1s;
  }

  & > img {
    height: 55px;
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 1148px) {
    & > img {
      height: 32px;
      padding: 0px 7px 0px 0px;
    }

    & {
      font-size: 1rem;
    }
  }
`;


const handleToggleInstructions = () => {
  const instructions = document.querySelector('div#instructions');
  if(instructions.className === 'hidden') {
    instructions.className = 'visible';
  } else {
    instructions.className = 'hidden';
  }
}

// const danceCountdownStart = dayjs('2024-01-12 21:00:00');
const danceCountdownStart = dayjs('2024-01-12 21:00:00');

function Scowl() {
  const [countDown, setCountDown] = useState();
  const jessVenmoUrl = "https://account.venmo.com/u/Jessica-JolleyGreene";
  const earlyBirdPrice = 24;
  const friday13Price = 13;
  const regularPrice = 30;
  const date = dayjs(new Date());
  const startFridayThe13 = dayjs('2024-09-13 00:00:00');
  const endFridayThe13 = dayjs('2024-09-13 23:59:59');
  const todayIsFriday13 = date > startFridayThe13 && date < endFridayThe13;
  const isRegularPrice = date >= dayjs('2024-10-25 00:00:01');
  const priceElement = isRegularPrice
    ? <><strong style={{fontSize: '1.4rem'}}>{`$${regularPrice}`}</strong></>
    : todayIsFriday13
    ? <><strike>{`$${regularPrice}`}</strike> <strong style={{fontSize: '1.4rem'}}>{`$${friday13Price}`}</strong> (Today Only)</>
    : <><strike>{`$${regularPrice}`}</strike> <strong style={{fontSize: '1.4rem'}}>{`$${earlyBirdPrice}`}</strong></>;
  const ticketButtonText = 'CLICK TO GET TICKETS';

  return (
    <AppContainer>
      <title>Scowl Halloween Dance Party</title>
      <HeroSection style={{marginBottom: '75px'}}>
        <div style={{backgroundColor: 'black'}}><img src="/images/scowl-dance-image.png" /></div>
        <div id="info-container">
          <div>
          <img src="/images/scowl-logo-black-bg.png" style={{maxWidth: '407px'}} />
          </div>
          <div className="top">
            <h1 style={{fontSize: '2.5rem'}}>Scowl Halloween Dance Party</h1>
            {
              todayIsFriday13 &&
              <p>
                <span style={{color: 'red', fontWeight: 'bold', fontSize: '1.3rem'}}>Friday the 13th Special!</span><br/>
                <span style={{fontSize: '1.3rem', color: '#ffeb82'}}>Pay only <strong style={{color: '#ffeb82'}}>$13</strong> per ticket!</span>
              </p>
              || ''
            }
            <p>Welcome to the Scowl Halloween Dance Party, a women-only Halloween event celebrating its third year in style. This year, we've upgraded to a fantastic new venue, decked out with amazing decorations that capture the fun and festive spirit of the night. The evening kicks off at 8 PM with complimentary photos to showcase your beauty and capture an unforgettable night out with your friends. Then, at 9 PM, the countdown begins for the main event—a dance party where a professional DJ will keep the energy high and the music pumping all night long. Get ready for a night of unforgettable fun, laughter, and dancing with friends! These dances were created to meet new people and make new friends. If you want to come but don't want to come alone, lets get you connected before the dance!</p>
          </div>
          <div style={{
            display: 'flex', 
            justifyContent: 'space-around', 
            width: '100%', 
            marginBottom: '20px', 
            alignItems: 'center',

          }}>
            <div style={{fontWeight: 'bold', fontSize: '1.5rem', color: '#d8b861'}}>Friday, October 25</div>
            <div style={{textAlign: 'center', color: '#d8b861'}}>Photos are included in<br/>ticket price!</div>
          </div>
          <div className="icon-section">
            <div>
              <div>📷</div>
              <div>Professional photos start at 8pm</div>
            </div>
            <div>
              <div>🎵</div>
              <div>Countdown starts at 9pm</div>
            </div>
            <div>
              <div>🏢</div>
              <div>@ Blacklights Ignite in Vernal</div>
            </div>
            <div>
              <div>🍰</div>
              <div>Light snack and drink provided</div>
            </div>
          </div>
          <div>
            <TicketButton onClick={handleToggleInstructions} href="#"><img alt="Scowl Logo" src="/images/scowl-logo.png" /> <span>{ticketButtonText}</span></TicketButton>
          </div>
          <div id="instructions" className="hidden">
            <p>Venmo Instructions</p>
            <p>
              <ol>
                <li>Go to "Payments/Requests".</li>
                <li>Select "Friend to Friend" for payment method.</li>
                <li>Pay {priceElement} for each ticket.</li>
                <li>In description, type "Scowl" and ticket qty.</li>
              </ol>
            </p>
            <p style={{textAlign:'center'}}>ALL SALES ARE FINAL 🙂</p>
            <div id="continue-to-venmo"><a target="_blank" rel="noreferrer" href={jessVenmoUrl}>Continue with Venmo</a></div>
            <div style={{textAlign: 'center'}} id="qr-venmo"><img alt="Venmo QR Code" style={{width:'160px'}} src="/images/qr-venmo.jpg" /></div>
          </div>
        </div>
      </HeroSection>
      <Analytics />
    </AppContainer>
  );
}

export default Scowl;